import {
  AttributeMap,
  Filters,
  ListingPlatform,
  ParsedBrowsingState,
  PLATFORM_DESKTOP,
  PLATFORM_HEADSET,
  PLATFORM_MOBILE,
  PLATFORM_PWA,
  PLATFORMS,
  RatingFilterQuery,
  SortQuery,
} from "@store-platform/types"
import { Categories, categoryNew } from ".."
import { first, omit, partition, take, without } from "lodash-es"

// =================== Parsing state from URL (segments, search) ===================
function inferSortingFromSegments(segments: string[]): SortQuery | undefined {
  if (segments.includes("popular")) {
    return "highest_rated"
  } else if (segments.includes("trending")) {
    return "most_reviewed"
  } else if (segments.includes(categoryNew.id)) {
    return "newest"
  } else {
    return undefined
  }
}

function inferMinRatingFromSegments(
  segments: string[],
): RatingFilterQuery | undefined {
  const rating = segments.find((segment) => !isNaN(parseFloat(segment)))
  if (
    (rating && rating === "3") ||
    rating === "3.5" ||
    rating === "4" ||
    rating === "4.5" ||
    rating === "5"
  ) {
    return rating as RatingFilterQuery
  } else {
    return undefined
  }
}

function inferPlatformsFromSegments(
  segment: string[],
): ListingPlatform[] | undefined {
  const platforms: ListingPlatform[] = []

  if (segment.includes("mobile")) {
    platforms.push(PLATFORM_MOBILE)
  }
  if (segment.includes("desktop")) {
    platforms.push(PLATFORM_DESKTOP)
  }
  if (segment.includes("headset")) {
    platforms.push(PLATFORM_HEADSET)
  }

  if (segment.includes("installable")) {
    platforms.push(PLATFORM_PWA)
  }

  return platforms.length > 0 ? platforms : undefined
}

export function parseSegmentsFromString(segments: string): string[] {
  return segments.split("_").filter((segment) => !!segment)
}

export function parseFiltersFromSegments(segments: string[]) {
  const filters: Filters = {}

  filters.sort_by = inferSortingFromSegments(segments)
  filters.min_rating = inferMinRatingFromSegments(segments)

  const sortAndFilterSegments = [
    "popular",
    "trending",
    "newest",
    "3",
    "3.5",
    "4",
    "4.5",
    "5",
  ]
  const attributes = without(segments, ...sortAndFilterSegments)

  const [platformSegments, attributesWithoutPlatform] = partition(
    attributes,
    (attribute) =>
      attribute === "mobile" ||
      attribute === "desktop" ||
      attribute === "headset" ||
      attribute === "installable",
  )
  const platforms = inferPlatformsFromSegments(platformSegments) || []

  filters.attribute = [...attributesWithoutPlatform, ...platforms]

  return filters
}

export function parseBrowsingState(filters?: Filters) {
  if (!filters) return {}

  let attributes
  if (typeof filters.attribute === "string") {
    attributes = [filters.attribute]
  } else {
    attributes = filters.attribute
  }

  const [categories, attrWithoutCategories] = partition(
    attributes,
    (attr) => Categories[attr] !== undefined,
  )

  const [platforms, attrWithoutPlatforms] = partition(
    attrWithoutCategories,
    (attr) => PLATFORMS.includes(attr as ListingPlatform),
  ) as [string[], string[]]

  return {
    attributes: [
      ...attrWithoutPlatforms,
      ...categories.map((cat) => `category.${cat}`),
      ...platforms,
    ],
    filters: omit(filters, "attribute"),
  } as ParsedBrowsingState
}

// =================== Generating URL from state ===================
function getSortingSegment(sortBy: SortQuery) {
  switch (sortBy) {
    case "highest_rated":
      return "popular"
    case "most_reviewed":
      return "trending"
    case "newest":
      return "newest"
    case "relevance":
    default:
      return ""
  }
}

function getPlatformSegments(platforms: string[]) {
  const segments = []
  if (platforms.includes(PLATFORM_MOBILE)) segments.push("mobile")
  if (platforms.includes(PLATFORM_DESKTOP)) segments.push("desktop")
  if (platforms.includes(PLATFORM_HEADSET)) segments.push("headset")
  if (platforms.includes(PLATFORM_PWA)) segments.push("installable")

  return segments
}

export function stateToQueryParams(
  state: ParsedBrowsingState,
): URLSearchParams {
  const params = new URLSearchParams()
  if (state.filters?.sort_by) {
    params.append("sort_by", state.filters.sort_by)
  }
  if (state.filters?.min_rating) {
    params.append("min_rating", state.filters.min_rating.toString())
  }

  if (state.attributes?.length) {
    state.attributes?.forEach((attribute) =>
      params.append("attribute", attribute),
    )
  }
  return params
}

function mergeStates(
  state: ParsedBrowsingState,
  changes: Partial<ParsedBrowsingState>,
) {
  return {
    ...state,
    ...changes,
    filters: { ...state.filters, ...changes.filters },
  }
}

export function generateSegmentUrl(state: ParsedBrowsingState): string {
  let segments = []
  if (state.filters?.sort_by) {
    segments.push(getSortingSegment(state.filters.sort_by))
  }
  if (state.filters?.min_rating) {
    segments.push(state.filters.min_rating)
  }

  const [categories, attrWithoutCategories] = partition(
    state.attributes,
    (attr) => attr.startsWith("category."),
  )

  const [platforms, attrWithoutPlatforms] = partition(
    attrWithoutCategories,
    (attr) => attr.startsWith("platform."),
  ) as [string[], string[]]

  const attributes = [
    ...categories.map((cat) => cat.split(".")?.[1] ?? cat),
    ...getPlatformSegments(platforms as ListingPlatform[]),
    ...attrWithoutPlatforms,
  ]
  segments = [...segments, ...attributes]

  return `/browse/${segments.join("_")}`
}

export function generateBrowsingURL(
  from: ParsedBrowsingState,
  to: ParsedBrowsingState,
): string {
  const state = mergeStates(from, to ?? {})
  if (state.searchQuery) {
    const prefix = `/search/${state.searchQuery}`
    const queries = stateToQueryParams(state)
    const search = queries.size > 0 ? `?${queries.toString()}` : ""

    return `${prefix}${search}`
  } else {
    return generateSegmentUrl(state)
  }
}

function toggleAttributeBrowsingState(
  state: ParsedBrowsingState,
  attributeSlug: string,
  toggle: boolean,
): ParsedBrowsingState {
  const attributes = toggle
    ? [...(state.attributes ?? []), attributeSlug]
    : state.attributes?.filter((attr) => attr !== attributeSlug)

  return mergeStates(state, { attributes })
}

export function getToggleAttributeLink(
  state: ParsedBrowsingState,
  attributeSlug: string,
  toggle: boolean,
) {
  const futureState = toggleAttributeBrowsingState(state, attributeSlug, toggle)
  return generateBrowsingURL(state, futureState)
}

export function stateToSegmentsString(state: ParsedBrowsingState): string {
  let segments = []
  if (state.filters?.sort_by) {
    segments.push(getSortingSegment(state.filters.sort_by))
  }
  if (state.filters?.min_rating) {
    segments.push(state.filters.min_rating.toString())
  }

  const attributes = (state.attributes ?? []).map((slug) =>
    slug.split(".").length > 1 ? slug.split(".")?.[1] : slug,
  )
  segments = [...segments, ...attributes]

  return segments.join("-")
}

// =================== SEO functions ===================
function getSortingTitle(sortBy?: SortQuery) {
  switch (sortBy) {
    case "highest_rated":
      return "Popular"
    case "most_reviewed":
      return "Trending"
    case "relevance":
    default:
      return "Best"
  }
}

function getPlatformTitle(platforms: ListingPlatform[]): string | undefined {
  const segments = []
  if (platforms.includes(PLATFORM_MOBILE)) segments.push("Mobile")
  if (platforms.includes(PLATFORM_DESKTOP)) segments.push("Desktop")
  if (platforms.includes(PLATFORM_HEADSET)) segments.push("Headset")

  return segments.length > 1 ? undefined : segments[0]
}

export function getStateTitle(
  state: ParsedBrowsingState,
  attributeMap: AttributeMap,
): string {
  const categories = take(
    state.attributes
      ?.filter((attr) => attr.startsWith("category."))
      .map((cat) => attributeMap[cat]?.name) || [],
    2,
  )

  const platforms = state.attributes?.filter((attr) =>
    attr.startsWith("platform."),
  ) as ListingPlatform[]

  const segments = [
    getSortingTitle(state.filters?.sort_by),
    formatCategoryList(categories),
  ]

  const platform = getPlatformTitle(platforms ?? [])
  if (platform) segments.push(platform)

  segments.push(platforms?.includes(PLATFORM_PWA) ? "PWAs" : "Web Apps & PWAs")

  return segments.join(" ")
}

export function getStateDescription(state: ParsedBrowsingState): string {
  const categories = state.attributes?.filter((attr) =>
    attr.startsWith("category."),
  )

  const firstCategory = Categories[first(categories)?.split(".")?.[1] || ""]
  if (!firstCategory) return "Browse through all of the apps on Store.app"
  return firstCategory?.description?.short
}

function formatCategoryList(categories: string[]) {
  if (!Array.isArray(categories) || categories.length === 0) {
    return ""
  }

  const numCategories = categories.length

  if (numCategories === 1) {
    return categories[0]
  } else if (numCategories === 2) {
    return categories.join(" and ")
  } else {
    const lastCategory = categories.pop()
    return `${categories.join(", ")}, and ${lastCategory}`
  }
}
