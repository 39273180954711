import(/* webpackMode: "eager", webpackExports: ["CategoryBar"] */ "/vercel/path0/apps/store-frontend/app/(platform)/(main)/(browse)/_components/CategoryBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AddToListTrigger"] */ "/vercel/path0/libs/ui/common/src/components/AddToList/AddToListTrigger.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/libs/ui/common/src/components/AppIcon/AppIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BadgeGroup"] */ "/vercel/path0/libs/ui/common/src/components/BadgeGroup/BadgeGroup.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/libs/ui/common/src/components/Carousel/Carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchButton"] */ "/vercel/path0/libs/ui/common/src/components/HeroBanner/SearchButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LaunchButton"] */ "/vercel/path0/libs/ui/common/src/components/LaunchButton/LaunchButton.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/libs/ui/common/src/components/Link/Link.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/libs/ui/common/src/components/Tooltip/Tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/libs/utils/src/lib/browser.ts");
;
import(/* webpackMode: "eager", webpackExports: ["EmailLinkErrorCode","isClerkAPIResponseError","isEmailLinkError","isKnownError","isMetamaskError"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
